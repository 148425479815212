import { Injectable } from '@angular/core';

declare const gtag: any;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  trackEvent(
    eventName: string,
    eventLabel: string,
    eventCategory: string,
    eventDescription?: string
  ) {
    gtag('event', eventName, {
      event_category: eventCategory, // High-level category (e.g., "Registration")
      event_label: eventLabel, // Descriptive label for the event
      value: eventDescription, // Optional description, use only if numeric or relevant
    });
  }
}
