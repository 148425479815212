import {
  ActivatedRoute,
  ParamMap,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Product, ProductResponse } from '../../models/product.model';

import { AnalyticsService } from '../../services/analytics.service';
import { ApiService } from '../../api/api.service';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-email-deal',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatChipsModule,
    MatRippleModule,
    MatButtonModule,
    MatRadioModule,
    FormsModule,
    MatProgressBarModule,
    CommonModule,
  ],
  templateUrl: './email-deal.component.html',
  styleUrl: './email-deal.component.scss',
})
export class EmailDealComponent {
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedPlan: string = '';
  userEmail: string = '';

  constructor(
    private api: ApiService,
    private router: Router,
    public route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.userEmail = params['email'];
    });
  }

  displayCorrectPrice(price: any): string {
    const correctTypePrice: number = price as number;
    if (correctTypePrice > 800) {
      return Math.floor(correctTypePrice / 12) + ' kr/månad';
    } else {
      return correctTypePrice + ' kr/månad';
    }
  }

  onClickCard(value: string) {
    this.selectedPlan = value;
  }

  proceedToPayment() {
    this.showSpinner$.next(true);
    if (this.selectedPlan && this.selectedPlan != '') {
      console.log('Should send log to backend');
      this.api.sendUserCheckoutInitialization().subscribe({
        next: () => {
          console.log('Post was successfully sent');

          // Fire Google Analytics
          this.analyticsService.trackEvent(
            'proceeded_to_payment', // Event Name: Specific action
            `From: Email, Plan: ${this.selectedPlan}`, // Event Label: Context of the action
            'Payment Process', // Event Category: Logical grouping
            'User started payment process' // Event Description: Optional
          );
        },
        error: () => {
          console.log('Error when sending POST');
        },
      });
      this.api
        .startStripeCheckoutSessionByEmail(this.selectedPlan, this.userEmail)
        .subscribe({
          next: (response) => {
            this.showSpinner$.next(false);
            window.open(response.url, '_self');
          },
          error: (error) => {
            this.showSpinner$.next(false);
            console.log('Fatal error when starting Stripe Check-out session!');
          },
          complete: () => {
            console.log('Start Stripe Check-Out session request COMPLETED');
          },
        });
    }
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  goToRegister() {
    this.router.navigate(['register']);
  }
}
