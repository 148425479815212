import { BehaviorSubject, merge } from 'rxjs';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';

import { AnalyticsService } from '../../services/analytics.service';
import { ApiService } from '../../api/api.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-register-account',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressBarModule,
    CommonModule,
  ],
  providers: [AnalyticsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './register-account.component.html',
  styleUrl: './register-account.component.scss',
})
export class RegisterAccountComponent {
  showErrorMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  errorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public email = new FormControl('', [Validators.required, Validators.email]);
  public password = new FormControl('', [Validators.required]);
  public checkBoxValue: boolean = false;

  emailErrorMessage = signal('');
  passwordErrorMessage = signal('');
  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  constructor(
    private api: ApiService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {
    merge(this.email.statusChanges, this.email.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateEmailErrorMessage());
    merge(this.password.statusChanges, this.password.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updatePasswordErrorMessage());
  }

  updateEmailErrorMessage() {
    if (this.email.hasError('required')) {
      this.emailErrorMessage.set('Du måste ange en mailadress');
    } else if (this.email.hasError('email')) {
      this.emailErrorMessage.set('Inte en giltig mailadress');
    } else {
      this.emailErrorMessage.set('');
    }
  }
  updatePasswordErrorMessage() {
    if (this.password.hasError('required')) {
      this.passwordErrorMessage.set('Du måste ange ditt lösenord');
    } else {
      this.passwordErrorMessage.set('');
    }
  }

  onCheckBoxChange(event: MatCheckboxChange) {
    this.checkBoxValue = event.checked;
  }

  goToPolicies() {
    window.open('https://wrebit.se/allmanna-villkor/', '_blank');
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  register() {
    this.showErrorMessage$.next(false);
    if (
      this.email.value != null &&
      this.email.value != '' &&
      this.password.value != null &&
      this.password.value != '' &&
      this.checkBoxValue
    ) {
      // Create new user first
      this.showSpinner$.next(true);
      this.api.register(this.email.value, this.password.value).subscribe({
        next: (response) => {
          localStorage.setItem('userToken', response.token);

          // Fire Google Analytics event
          this.analyticsService.trackEvent(
            'user_registered', // Event Name
            'Registration Successful', // Event Label
            'Registration', // Event Category
            'User signed up successfully' // Event Description (optional)
          );

          // Create empty company after successful user registration
          // (company required for creating subscriptions in database)
          this.api
            .createCompany({ address: '', city: '', email: '', phoneNr: '' })
            .subscribe({
              next: (companyResponse) => {
                this.showSpinner$.next(false);
                console.log(
                  'Successfully created company, proceed to select-payment!'
                );
                this.router.navigate(['select-payment']);
              },
              error: (companyError) => {
                this.showSpinner$.next(false);
                this.showErrorMessage$.next(true);
                this.errorMessage$.next(
                  'Nånting gick fel. Vänligen vänta en stund och försök sedan igen.'
                );
                console.log('Fatal error when creating company!');
              },
              complete: () => {
                console.log('Create company request COMPLETE');
              },
            });
        },
        error: (error) => {
          this.showSpinner$.next(false);
          this.showErrorMessage$.next(true);
          console.log('Error when registering.');
          const errorResponse = error.error as string;
          if (errorResponse.includes('duplicate key value')) {
            this.errorMessage$.next(
              'Den här e-postadressen är redan registrerad. Vänligen logga in.'
            );
          } else {
            this.errorMessage$.next(
              'Nånting gick fel. Vänligen vänta en stund och försök sedan igen.'
            );
          }
        },
        complete: () => {
          console.log('Register request completed.');
        },
      });
    } else {
      this.showErrorMessage$.next(true);
      this.errorMessage$.next('Du måste ange både e-postadress och lösenord.');
    }
  }
}
